<template>
  <div class="main-master-wrapper">
    <Breadcrumb :breadcrumbs="breadcrumbs" mainLabelName="Текущие"></Breadcrumb>
    <a-tabs>
      <a-tab-pane key="1" tab="Сбор заявок">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getCustomerTendersStatusOneLoaded" />
          <EmptyVue
              v-if="this.$store.getters.getCustomerTendersStatusOneLoaded &&
                    this.$store.getters.getCustomerTendersStatusOne.length == 0" type="customerNewTender">
          </EmptyVue>
          <div class="cell_wrapper" v-if="this.$store.getters.getCustomerTendersStatusOneLoaded &&
                    this.$store.getters.getCustomerTendersStatusOne.length != 0">
              <CustomerTenderCell
                v-for="tenderEl in this.$store.getters
                  .getCustomerTendersStatusOne"
                :key="tenderEl.id"
                :tender="tenderEl"
                class="announcement-doing-cell"
                link="Tender"
              ></CustomerTenderCell>
              <div class="pagination">
                <a-pagination
                  :current="currentPageStatusOne"
                  :total="this.$store.getters.getCustomerTendersStatusOneTotal"
                  :showLessItems="true"
                  show-less-items
                  :defaultPageSize="6"
                  :hideOnSinglePage="true"
                  @change="onChangeStatusOnePagination"
                />
              </div>
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="2" tab="В работе">
        <TabViewContainer>
          <Loader class="cell_wrapper" v-if="!this.$store.getters.getCustomerTendersStatusTwoLoaded" />
<!--          <div class="cell_wrapper" v-if="this.$store.getters.getCustomerTendersStatusTwoLoaded || (this.$store.getters.getCustomerTendersStatusTwo && this.$store.getters.getCustomerTendersStatusTwo.length === 0)">-->
<!--            <EmptyVue type="customerNewTender"></EmptyVue>-->
<!--          </div>-->
          <div class="cell_wrapper" v-if="this.$store.getters.getCustomerTendersStatusTwoLoaded">
            <!-- {{this.$store.getters.getCustomerTendersStatusTwo}} -->
            <CustomerTenderCell
              v-for="tenderEl in this.$store.getters
                .getCustomerTendersStatusTwo"
              :key="tenderEl.id"
              :tender="tenderEl"
              class="announcement-doing-cell"
              link="Tender"
            ></CustomerTenderCell>
            <div class="pagination">
              <a-pagination
                :current="currentPageStatusTwo"
                :total="this.$store.getters.getCustomerTendersStatusTwoTotal"
                :showLessItems="true"
                show-less-items
                :defaultPageSize="6"
                :hideOnSinglePage="true"
                @change="onChangeStatusTwoPagination"
              />
            </div>
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Завершенные">
        <TabViewContainer>
          <Loader v-if="!this.$store.getters.getCustomerTendersStatusThreeLoaded" />
<!--          <div v-if="this.$store.getters.getCustomerTendersStatusThreeLoaded || (this.$store.getters.getCustomerTendersStatusThree && this.$store.getters.getCustomerTendersStatusThree.length === 0)">-->
<!--            <EmptyVue type="customerNewTender"></EmptyVue>-->
<!--          </div>-->
          <div class="cell_wrapper" v-if="this.$store.getters.getCustomerTendersStatusThreeLoaded">
            <CustomerTenderCell
              v-for="tenderEl in this.$store.getters
                .getCustomerTendersStatusThree"
              :key="tenderEl.id"
              :tender="tenderEl"
              class="announcement-doing-cell"
              link="Tender"
            ></CustomerTenderCell>
            <div class="pagination">
              <a-pagination
                :current="currentPageStatusThree"
                :total="this.$store.getters.getCustomerTendersStatusThreeTotal"
                :showLessItems="true"
                show-less-items
                :defaultPageSize="6"
                :hideOnSinglePage="true"
                @change="onChangeStatusThreePagination"
              />
            </div>
          </div>
        </TabViewContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import CustomerTenderCell from '@/components/CustomerComponents/CustomerTenderCell.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Loader from '@/components/common/Loader.vue'

export default {
  components: {
    CustomerTenderCell,
    Breadcrumb,
    EmptyVue,
    TabViewContainer,
    Loader
  },
  data () {
    return {
      currentPageStatusOne: 1,
      currentPageStatusTwo: 1,
      currentPageStatusThree: 1,
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Mои Тендеры',
          link: '/my_tenders'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch(
      'setCustomerTendersStatusOne',
      this.currentPageStatusOne
    )
    this.$store.dispatch(
      'setCustomerTendersStatusTwo',
      this.currentPageStatusTwo
    )
    this.$store.dispatch(
      'setCustomerTendersStatusThree',
      this.currentPageStatusThree
    )
  },
  methods: {
    onChangeStatusOnePagination (current) {
      this.currentPageStatusOne = current
      this.$store.dispatch('setCustomerTendersStatusOne', {
        page: this.currentPageStatusOne
      })
    },
    onChangeStatusTwoPagination (current) {
      this.currentPageStatusTwo = current
      this.$store.dispatch('setCustomerTendersStatusTwo', {
        page: this.currentPageStatusTwo
      })
    },
    onChangeStatusThreePagination (current) {
      this.currentPageStatusThree = current
      this.$store.dispatch('setCustomerTendersStatusThree', {
        page: this.currentPageStatusThree
      })
    }
  }
}
</script>

<style>
.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}
.main-master-wrapper {
  background-color: white;
  width: 100%;
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 10px;
  background-color: white;
  border-radius: 8px;
}

.cell_wrapper{
  width: 100%;
  height: 100%;
}
</style>
